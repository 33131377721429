body {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    margin: 0;
    overflow: hidden;
    width: 100vw;
}

canvas {
    height: 100%;
    inset: 0;
    position: absolute;
    width: 100%;
}

#begin-prompt {
    margin: 18px;
    z-index: 10;
}

#click {
    display: none;
}

.progress-area {
    display: none;
}

.progress-area--active {
    display: grid;
}

.slideshow-container {
	display: flex;
	justify-content: space-between;
	margin:auto;
}

.prev, .next {
	width: 100px;
	height: 730px;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;
	visibility:hidden;
}


.slides {
	max-width: 100%;
}
#unity-footer
{
	display:none;
}
#didacticContent
{
	display:none;
	margin:auto;
	margin-top:10px;
	flex-direction:column;
	align-items: center;

}

#slideshowBackButton
{
	width:50px;
	height:50px;

}

#slideshowNavbar
{
	display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

#presentationTitle
{
    font-size: 40px;
    flex-grow: 1;
    text-align: center;
    font-family: "OpenSans-Bold", Arial, sans-serif;
}

